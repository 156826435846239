import { apiSlice } from '../../app/api/apiSlice';
import CandDetails from '../../pages/userworkspace/mytask/hiringstage/offer/offerdetails/CandDetails';

export const joiningApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Joining', 'JoiningList', 'JoiningDetails', 'JoiningApproval'],
  endpoints: (builder) => ({
    joining: builder.query({
      query: () => ({
        url: '/joining',
        method: 'GET',
      }),
      providesTags: ['Joining'],
      // keepUnusedDataFor: 5,
    }),
    joiningApprovalList: builder.query({
      query: () => ({
        url: '/joining/approval',
        method: 'GET',
      }),
      providesTags: ['JoiningApproval'],
      keepUnusedDataFor: 5,
    }),
    candJoininglist: builder.query({
      query: (id) => ({
        url: `/joining/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['JoiningList'],
      keepUnusedDataFor: 5,
    }),
    candJoiningDetails: builder.query({
      query: (id) => ({
        url: `/joining/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['JoiningDetails'],
      keepUnusedDataFor: 5,
    }),
    joiningNegotation: builder.mutation({
      query: (data) => ({
        url: '/joining/candidate/negotation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['JoiningList', 'JoiningDetails', 'JoiningApproval'],
    }),

    joiningCandidate: builder.mutation({
      query: (data) => ({
        url: `/joining/candidate/${data.profile_id}`,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['JoiningList', 'JoiningDetails', 'JoiningApproval'],
    }),

    joiningApproval: builder.mutation({
      query: (data) => ({
        url: '/joining/candidate/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['JoiningList', 'JoiningDetails', 'JoiningApproval'],
    }),
    // code for tist
    joiningNewQuestions: builder.mutation({
      query:(data) => ({
        url: '/joining/candidate_newQtsn',
        method: 'POST',
        body:{...data},
      }),
    }),
    getNewFollowupQstn: builder.query ({
      query: (id) => ({
        url: `/joining/candidate_newQstn/${id}`,
        method: 'GET',
      }),
    }),
    addScoreToNewQstn: builder.mutation({
      query: (data) =>({
        url: '/joining/candidate_newQstn/score',
        method: 'POST',
        body: {...data}
      })
    }),
    getNewQstnScore: builder.query({
      query: (id) => ({
        url: `/joining/candidate_newQstnscore/${id}`,
        method:'GET',
      })
    }),
  }),
});

export const {
  useJoiningQuery,
  useJoiningApprovalListQuery,
  useCandJoininglistQuery,
  useCandJoiningDetailsQuery,
  useJoiningNegotationMutation,
  useJoiningCandidateMutation,
  useJoiningApprovalMutation,
  useJoiningNewQuestionsMutation,
  useGetNewFollowupQstnQuery,
  useAddScoreToNewQstnMutation,
  useGetNewQstnScoreQuery,
} = joiningApiSlice;
