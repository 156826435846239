import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  ArrowDownTrayIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';

import { useSelector } from 'react-redux';

import * as yup from 'yup';

import CandDetails from './CandDetails';

import {
  useCandHandoverDetailsQuery,
  useHandoverUpdateApprovalMutation,
} from '../../../../../features/hiringstages/handoverApiSlice';
import Modal from '../../../../../components/common/Modal';


const HandoverDetails = ({ toggleHandover }) => {
  const [showNotification, setShowNotification] = useState(false);
  const candId = useSelector(selectCandidateInterview);
  const [openModal, setOpenModal] = useState(false);
  console.log(candId)
  const {
    data: canddetails,
    isLoading: canddetailsLoading,
    isSuccess,
  } = useCandHandoverDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  // const [handoverNegotation, { isLoading: handoverNegotationLoading }] =
  //   useHandoverNegotationMutation();


  const [handoverUpdateApproval, { isLoading: handoverApprovalLoading }] =
    useHandoverUpdateApprovalMutation();
  const canddetailsObj = canddetails?.data?.documents;



  console.log(canddetails)
  const checklistDet = [
    { name: 'Welcome Email', status: canddetails?.data?.welcome_email_sent === 1 },
    {
      name: 'All Documents Collected',
      status: Array.isArray(canddetails?.data?.documents) &&
        canddetails.data.documents.length > 0 &&
        canddetails.data.documents.every(doc => doc.status === '1')
    },
    {
      name: 'Candidate Reported to Office',
      status: Array.isArray(canddetails?.data?.onboarding) &&
        canddetails.data.onboarding.some(onboard => onboard.status === true)
    }
  ];
  console.log(checklistDet)
  const people = [{ Process: checklistDet }];

  const initialValues = {
    profile_id: candId,
    instruction_kit_sent: canddetails?.data?.instruction_kit_sent || '',
    handover_priority: canddetails?.data?.handover_priority || '',
    handover_comments: canddetails?.data?.handover_comments || '',
    welcome_email_sent: canddetails?.data?.welcome_email_sent || 0,
    handover_approval_status: 1
  };
  const validationSchema = yup.object({
    // handover_comments: yup.string().required('please enter the feedback'),
  });

  const onSubmit = async (initialValues) => {
    console.log(initialValues)
    try {
      const rrrr = await handoverUpdateApproval(initialValues).unwrap()
      alert('Data Approve successfully ');
      gotoHandoverList('handoverlist')
    } catch (error) {
      alert('Error');
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleClick = async (btnStatus) => {
    let newData;
    if (btnStatus === 'Query') {
      newData = { ...formik.values, handover_approval_status: 2 };
    }
    if (btnStatus === 'Reject') {
      newData = { ...formik.values, handover_approval_status: 3 };
    }
    console.log(newData)
    try {
      const rrrr = await handoverUpdateApproval({
        ...newData,
      }).unwrap();

      alert(`Data ${btnStatus} successfully`);
      gotoHandoverList('handoverlist')
    } catch (error) {
      alert('Error');
    }
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const gotoHandoverList = (value, candid) => {
    toggleHandover(value);
  };

  const openModalWindow = (id) => {
    // setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) { }
  };

  if (canddetailsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoHandoverList('handoverlist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-row mt-0  gap-4">
          <CandDetails canddetails={canddetails} />

          {/* Content Soft */}

          <div className="basis-5/6">
            <div className="flex flex-row  ">
              <div className="w-full ">
                <div className="bg-white rounded-lg">
                  <div className="">
                    <div className="">
                      <div className="bg-gray-200  py-5 rounded-lg  px-5">
                        <div className="kb-file-upload mb-6  flex justify-between">
                          <div>
                            <h1 className="font-bold text-xl">
                              Document from Candidate
                            </h1>
                          </div>
                        </div>

                        <div className="mt-2 flow-root">
                          <div className="">
                            <div className="inline-block min-w-full py-2 align-middle">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                      >
                                        File Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        Attachments
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left flex justify-end mr-6 text-sm font-bold text-gray-900"
                                      >
                                        {/* Action */}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {Array.isArray(canddetailsObj) && canddetailsObj.map((document) => (
                                      <tr key={document.id}>
                                        <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {/* {person.name} */}
                                          {document.title}
                                        </td>
                                        <td className="whitespace-nowrap  text-sm text-gray-500">
                                          {/* {person.title} */}
                                          <span className=" flex whitespace-nowrap py-2 text-sm text-gray-500">
                                            <a
                                              href={document.document_path}
                                              target="_blank"
                                              title="Edit"
                                              className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                            >
                                              {' '}
                                              <ArrowDownTrayIcon
                                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                aria-hidden="true"
                                              />{' '}
                                              Download
                                            </a>
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap flex justify-end mr-6  text-sm text-gray-500">

                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between mt-3">
                          <h1 className="text-xl font-bold text-center self-center">
                            Onboarding Checklist
                          </h1>
                        </div>
                        <div className="mt-2 flow-root">
                          <div className="">
                            <div className="inline-block min-w-full py-2 align-middle">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                    <tr>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6">
                                        Process
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {people[0].Process.map((item, index) => (
                                      <tr key={index}>
                                        <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {item.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                          {item.status ? (
                                            <CheckIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                                          ) : (
                                            <span className="text-red-600">Pending</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mx-0 mt-0 w-ful">
                          <div className=" mt-0 space-x-5 w-full flex justify-end">
                            <button
                              type="submit"
                              className=" rounded-lg  bg-green-400 hover:bg-green-600 text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
    </>
  );
};
export default HandoverDetails;
