import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useCandOfferlistQuery,
  useNegotationMutation,
} from '../../../../../../../features/hiringstages/offerApiSlice';

const Negotation = ({ canddetails, toggleOffer }) => {
  let { id } = useParams();
  const canddetailsObj = canddetails?.data;
  const [negotation] = useNegotationMutation();

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    // negotiated_salary: canddetailsObj?.negotiated_salary || '',
    negotiated_salary: canddetailsObj?.negotiated_salary || '6000',
    negotiated_doj: canddetailsObj?.negotiated_doj || '',
    negotiated_designation: canddetailsObj?.negotiated_designation || '',
    negotiated_notice_period: canddetailsObj?.negotiated_notice_period || '',
    payout_option_required: canddetailsObj?.payout_option_required || '',
    payout_amount: canddetailsObj?.payout_amount || '',
    negotiation_comments: canddetailsObj?.negotiation_comments || '',
    negotiation_approval_status: 1,

    negotiation_priority: canddetailsObj?.negotiation_priority || 0,
    negotiation_feedback: '',
    offer_approval_status: 2,
    candidate_approval_status: 0
  };

  const validationSchema = id
    ? yup.object({
      negotiated_salary: yup
        .string()
        .required('Please enter negotiate salary'),
      negotiated_doj: yup
        .string()
        .required('Please select the date of joining'),
      negotiated_notice_period: yup
        .string()
        .required('please enter the notice period'),
      payout_option_required: yup
        .string()
        .required('Please select the Buyout option '),
      payout_amount: yup.string().required('Please enter the Buyout amount'),
      negotiated_designation: yup
        .string()
        .required('Please select the designation '),
      negotiation_comments: yup
        .string()
        .required('Please enter the Comments'),
      negotiation_priority: yup
        .string()
        .required('Please select the priority'),
    })
    : '';

  const onSubmit = async (validationSchema) => {
    console.log(validationSchema)
    try {
      const rrrr = await negotation(validationSchema).unwrap();
      alert('Data Add Successfully- mytask');
      toggleOffer('offerlist', 'offer')
    } catch (error) { }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" bg-gray-200 py-4 rounded-lg px-4">
          <div className=" rounded-md shadow-3xl mx-0">
            {' '}
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2 mt-1">
                <div className="self-center">
                  <label
                    htmlFor="expected_salary"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Expected Salary
                  </label>
                </div>
                <div className="mt-3">
                  <span> {canddetails?.data.salary_expectation}</span>
                </div>
              </div>

              <div className="grid grid-cols-2">
                <div className="mt-3 ">
                  <label
                    htmlFor="negotiated_salary"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Negotiated Salary
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="negotiated_salary"
                    id="negotiated_salary"
                    autoComplete="negotiated_salary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.negotiated_salary}
                    placeholder="25 L"
                    className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  />
                  {formik.touched.negotiated_salary &&
                    formik.errors.negotiated_salary ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.negotiated_salary}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2 mt-1">
                <div className="self-center">
                  <label
                    htmlFor="negotiated_doj"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Negotiated DOJ
                  </label>
                </div>
                <div>
                  <input
                    type="date"
                    name="negotiated_doj"
                    id="negotiated_doj"
                    className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.negotiated_doj}
                  />

                  {formik.touched.negotiated_doj &&
                    formik.errors.negotiated_doj ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.negotiated_doj}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-2 mt-1">
                <div className="mr-6 self-center">
                  <label
                    htmlFor="notice_period"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Notice Period
                  </label>
                </div>
                <div>
                  <select
                    id="negotiated_notice_period"
                    name="negotiated_notice_period"
                    autoComplete="negotiated_notice_period"
                    className="bg-white border border-inputBorder mt-0 w-40 rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.negotiated_notice_period}
                  >
                    <option>-- Select --</option>
                    <option value={'10'}>10 days</option>
                    <option value={'15'}>15 days</option>
                  </select>
                  {formik.touched.negotiated_notice_period &&
                    formik.errors.negotiated_notice_period ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.negotiated_notice_period}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2">
                <div className="mt-3 ">
                  <label
                    htmlFor="payout_option_required"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Buyout Option
                  </label>
                </div>
                <div>
                  <select
                    id="payout_option_required"
                    name="payout_option_required"
                    autoComplete="payout_option_required"
                    className="bg-white border border-inputBorder mt-0 w-40 rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.payout_option_required}
                  >
                    <option>-- Select --</option>
                    <option value={'3'}>Yes</option>
                    <option value={'2'}>No </option>
                  </select>

                  {formik.touched.payout_option_required &&
                    formik.errors.payout_option_required ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.payout_option_required}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-2">
                <div className="mt-3 mr-5">
                  <label
                    htmlFor="payout_amount"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Buyout Amount
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    name="payout_amount"
                    id="payout_amount"
                    autoComplete="payout_amount"
                    placeholder="5 L"
                    className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.payout_amount}
                  />
                  {formik.touched.payout_amount &&
                    formik.errors.payout_amount ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.payout_amount}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2">
                <div className="mt-3 mr-6 ">
                  <label
                    htmlFor="negotiated_designation"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Designation Negotiated
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="negotiated_designation"
                    id="negotiated_designation"
                    autoComplete="negotiated_designation"
                    className="bg-inputbg border border-inputBorder mt-1 w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.negotiated_designation}
                  />
                  {formik.touched.negotiated_designation &&
                    formik.errors.negotiated_designation ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.negotiated_designation}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-0 my-3 grid ">
          <div>
            <label
              htmlFor="negotiation_comments"
              className="block text-sm font-semibold text-gray-700 pb-2"
            >
              Comments
            </label>

            <textarea
              rows={5}
              name="negotiation_comments"
              id="negotiation_comments"
              className="bg-inputbg w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder=""
              defaultValue={''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.negotiation_comments}
            />
            {formik.touched.negotiation_comments &&
              formik.errors.negotiation_comments ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.negotiation_comments}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mx-0 mt-0">
          <div className="flex flex-row mt-0">
            <div className="w-full bg-gray-100  border py-0 flex rounded-lg justify-between">
              <div className=" p-3 mr-3">
                <select
                  id="negotiation_priority"
                  name="negotiation_priority"
                  autoComplete="negotiation_priority"
                  className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.negotiation_priority}
                >
                  <option>-- Select Priority --</option>
                  <option value={'3'}>High</option>
                  <option value={'2'}>Medium</option>
                  <option value={'1'}>Low</option>
                </select>
                {/* {formik.touched.priority && formik.errors.priority ? ( */}
                {formik.touched.negotiation_priority &&
                  formik.errors.negotiation_priority ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.negotiation_priority}
                  </div>
                ) : null}
                {/* ) : null} */}
              </div>
              <div className=" py-3 mr-3">
                <button
                  type="submit"
                  className=" rounded-xl border bg-green-500 hover:bg-green-700 w-full py-2.5 px-6 text-sm font-medium text-white shadow-sm focus:outline-none "
                >
                  Submit for Approval
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Negotation;
