import { apiSlice } from '../../app/api/apiSlice';
// import CandDetails from '../../pages/userworkspace/mytask/hiringstage/handover/handoverdetails/CandDetails';

export const handoverApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Handover', 'HandoverList', 'HandoverDetails', 'HandoverApproval'],
  endpoints: (builder) => ({
    handover: builder.query({
      query: () => ({
        url: '/handover',
        method: 'GET',
        keepUnusedDataFor: 5,
      }),
      providesTags: ['Handover'],
      // keepUnusedDataFor: 5,
    }),
    handoverApproval: builder.query({
      query: () => ({
        url: '/handover',
        method: 'GET',
      }),
      providesTags: ['HandoverApproval'],
      keepUnusedDataFor: 5,
    }),
    candHandoverlist: builder.query({
      query: (id) => ({
        url: `/handover/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['HandoverList'],
      keepUnusedDataFor: 5,
    }),
    candHandoverDetails: builder.query({
      query: (id) => ({
        url: `/handover/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['HandoverDetails'],
      keepUnusedDataFor: 5,
    }),
    handoverNegotation: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/negotation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['HandoverList', 'HandoverDetails', 'HandoverApproval'],
    }),

    handoverCandidate: builder.mutation({
      query: (id) => ({
        url: `/handover/candidate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['HandoverList', 'HandoverDetails', 'HandoverApproval'],
    }),
    handoverUpdateApproval: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['HandoverList', 'HandoverDetails', 'HandoverApproval', 'handoverUpdateApproval'],
    }),
    // offerletterSubmit: builder.mutation({
    //   query: (data) => ({
    //     url: '/offer/candidate/offer_letter',
    //     method: 'PUT',
    //     body: { ...data },
    //   }),
    //   invalidatesTags: ['OfferList', 'OfferDetails'],
    // }),
    // code for l-tist
    handoverCandDocDelete: builder.mutation({
      query: (id) => ({
          url: `/handover/candidate/doc/${id}`,
          method: 'DELETE',
        }),
      providesTags: ['HandoverDocUpdate']
    }),
    handoverChecklist: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/documents',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Handover', 'HandoverList', 'HandoverDetails'],
    }),
    handoverCandChecklistDelete: builder.mutation({
      query: (id)  => ({
          url: `/handover/candidate/checklist/${id}`,
          method: 'DELETE',
        }),
      providesTags: ['HandoverChecklistUpdate']
    }),
    // code for tist
    candWelcomeMail: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/sendWelcomeMail',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Handover', 'HandoverList', 'HandoverDetails','candWelcomeMail'],
    }),
    candidateSubmitReport: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/candidateReportToOff',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Handover', 'HandoverList', 'HandoverDetails','candWelcomeMail','candidateReportToOff'],
    }),
  }),
});

export const {
  useHandoverQuery,
  useCandHandoverlistQuery,
  useCandHandoverDetailsQuery,
  useHandoverNegotationMutation,
  useHandoverCandidateMutation,
  useHandoverUpdateApprovalMutation,
  // useOfferletterSubmitMutation,
  useHandoverCandDocDeleteMutation,
  useHandoverChecklistMutation,
  useHandoverCandChecklistDeleteMutation,
  useCandWelcomeMailMutation,
  useCandidateSubmitReportMutation
} = handoverApiSlice;
