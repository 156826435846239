import { apiSlice } from '../../app/api/apiSlice';
import CandDetails from '../../pages/userworkspace/mytask/hiringstage/offer/offerdetails/CandDetails';

export const offerApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    'Offer',
    'OfferList',
    'OfferDetails',
    'OfferApproval',
    'OfferApproval',
  ],
  endpoints: (builder) => ({
    offer: builder.query({
      query: () => ({
        url: '/offer',
        method: 'GET',
      }),
      providesTags: ['Offer'],
      keepUnusedDataFor: 5,
    }),
    offerApproval: builder.query({
      query: () => ({
        url: '/offer/approval',
        method: 'GET',
      }),
      providesTags: ['OfferApproval'],
      keepUnusedDataFor: 5,
    }),
    negotation: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/negotation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    offerletterSubmit: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/offer_letter',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    offerletterApproval: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    candOfferlist: builder.query({
      query: (id) => ({
        url: `/offer/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['OfferList'],
      keepUnusedDataFor: 5,
    }),

    candOfferDetails: builder.query({
      query: (id) => ({
        url: `/offer/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['OfferDetails'],
      keepUnusedDataFor: 5,
    }),

    offerDocument: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/documents',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),
    //addded by l-tist
    createOfferTemp: builder.mutation({
      query: (data) => ({
        url: '/offer/create_offer_letter',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['OfferTemp']
    }),
    getOfferTemp: builder.query({
      query: () => ({
        url: '/offer/view_offer_letter',
        method: 'GET',
      }),
      providesTags: ['OfferTemp'],
      keepUnusedDataFor: 5,
    }),
    updateOfferTemp: builder.mutation({
      query: (data) => ({
        url: 'offer/edit_offerTemp',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferTemp']
    }),
    deleteOfferTemp: builder.mutation({
      query: (id) => ({
        url: `offer/delete_offerTemp/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['OfferTemp']
    }),
    //end here
    // code for tist
    listOfferTemplate: builder.query({
      query: (id) => ({
        url: `/offer/listOffTemplate`,
        method: 'GET',
      }),
      providesTags: ['listOfferTemplate'],
      keepUnusedDataFor: 5,
    }),
    updateOfferLetter: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/update_OfferLetter',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),
    confirmCandidate: builder.mutation({
      query: (confirmationData) => ({
        url: '/offer/candidateConfirm',
        method: 'POST',
        body: confirmationData, // Sending the candidateId and response
      }),
      invalidatesTags: ['Confirmation'],
    }),
  }),
});

export const {
  useOfferQuery,
  useOfferApprovalQuery,
  useCandOfferlistQuery,
  useCandOfferDetailsQuery,
  useNegotationMutation,
  useOfferletterApprovalMutation,
  useOfferletterSubmitMutation,
  useOfferDocumentMutation,
  useListOfferTemplateQuery,
  useUpdateOfferLetterMutation,
  useCreateOfferTempMutation,
  useGetOfferTempQuery,
  useUpdateOfferTempMutation,
  useDeleteOfferTempMutation,  
  useConfirmCandidateMutation 
} = offerApiSlice;
