import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useGetInterviewCandDetailsQuery } from '../../../../../../../features/hiringstages/interviewApiSlice';
import { selectCandidateInterview } from '../../../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';

function CandResume() {
  const candId = useSelector(selectCandidateInterview);
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useGetInterviewCandDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });
  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    // return canddetails?.data?.resume != null ? (
    <>
      {/* <div> {canddetails?.data?.resume}</div> */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
          }}
        >
          <Viewer fileUrl="/resume/static-resume.pdf" />
        </div>
      </Worker>
    </>
    // ) : (
    //   // <Viewer
    //   //   fileUrl={resume}
    //   //   plugins={[
    //   //     // Register plugins
    //   //     defaultLayoutPluginInstance,
    //   //   ]}
    //   // />
    //   'No resume found'
  );
}

export default CandResume;
