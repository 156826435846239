import React from 'react';
import { useCandJoininglistQuery } from '../../../../../features/hiringstages/joiningApiSlice';
import { Disclosure } from '@headlessui/react';
import { PencilSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setCandId } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';

function JobCandidateList({ toggleJoining, JobRequestId }) {
  const dispatch = useDispatch();
  const {
    data: joiningcandList,
    error: joiningcandListError,
    isLoading: joiningcandListLoading,
    isFetching: joiningcandListFetching,
    isSuccess: joiningcandListSuccess,
  } = useCandJoininglistQuery(JobRequestId, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const joiningcandListObj = joiningcandList?.data;

  const gotoJoiningDetails = (value, obj) => {
    if (obj?.id > 0) {
      dispatch(setCandId(obj.id));
    } else {
      dispatch(setCandId(0));
    }
    toggleJoining(value, obj);
  };
  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };

  if (joiningcandListLoading || joiningcandListFetching) {
    return <div>Loading...</div>;
  }

  const getJoiningStatus = (statusId, negotationStatusId, canApproveStatusId) => {
    console.log(statusId, negotationStatusId, canApproveStatusId)
    if (statusId == 0 || statusId == null) {
      return (
        <span className="text-black px-6 py-2 block w-full text-center">
          Yet to Approve
        </span>
      );
    } else if (statusId === 1 && negotationStatusId == 2 && canApproveStatusId == 1) {
      return (
        <span className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          Approved
        </span>
      );
    } else if (negotationStatusId === 1 || canApproveStatusId == 0 || canApproveStatusId == null) {
      return (
        <span className="block rounded-md bg-yellow-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {/* Submitted for Approve - Candidate */}
          Waiting for Candidate Approved
        </span>
      );
    } else if (statusId === 2) {
      return (
        <span className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          Rejected
        </span>
      );
    } else if (statusId === 3) {
      return (
        <span className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          Query
        </span>
      );
    }
  };
  if (joiningcandListSuccess) {
    return (
      <Disclosure.Panel as="dd" className="mt-0 pr-0">
        <div className="text-base leading-7 text-gray-600">
          {/* <h3>Bottom</h3> */}
          <div className="flex">
            <div className=" gap-4 basis-full">
              <div className="bg-white shadow-3xl gap-4 rounded-md ">
                <div className="bg-white rounded-md shadow-3xl border border-gray-100">
                  {/* <Interviewinterview /> */}

                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center"></div>

                    <div className="mt-0 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg w-[100.5rem] overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-100 text-left text-md font-bold text-gray-900">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  sm:pl-6"
                                  >
                                    Candidate Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Expected Salary
                                  </th>

                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Negotiated Salary
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Notice Period
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Negotiated DOJ
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Designation Negotiated
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Comments
                                  </th>

                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Buyout Option
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Approved by
                                  </th>

                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Negotiated Feedback
                                  </th>

                                  {/* <th scope="col" className="px-3 py-3.5 ">
                                Job Title
                              </th> */}
                                  {/* <th scope="col" className="px-3 py-3.5 ">
                              Interview Stage
                            </th> */}
                                  {/* <th scope="col" className="px-3 py-3.5 ">
                              Previous Interviewer
                            </th> */}
                                  {/* 
                            <th scope="col" className="px-3 py-3.5 ">
                              Interviewer Recommendation
                            </th> */}

                                  {/* <th scope="col" className="px-3 py-3.5 ">
                              Previous Interview Date
                            </th> */}

                                  {/* <th
                              scope="col"
                              className="px-3 py-3.5 text-center"
                            >
                              Next Interview Date
                            </th> */}

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Overall Score
                                  </th>

                                  {/* <th scope="col" className="px-3 py-3.5 ">
                              Next Interviewer
                            </th> */}
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Timeline Status
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Joining Status
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                     Action{' '}
                                  </th>
                                </tr>
                              </thead>
                              {joiningcandListObj?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  {joiningcandListObj?.map((joining, i) => (
                                    <>
                                      {/* <pre>{JSON.stringify(interview)}</pre> */}
                                      <tr key={i}>
                                        <td className="whitespace-nowrap py-2 w-2/12 pl-4 pr-3 text-sm  sm:pl-6 border-r-2">
                                          {joining.id} - {joining.fullName}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.salary_expectation}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.payout_amount}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.negotiated_notice_period}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.negotiated_doj}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.negotiated_designation}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.negotiation_comments}
                                        </td>

                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.payout_option_required}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {getUsername(joining.joining_approved_by)}
                                        </td>

                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {joining.negotiation_feedback}
                                        </td>
                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                    {joining.job_title}
                                  </td> */}

                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                    {
                                      joining.current_interview[0]
                                        ?.interview_stage.sno
                                    }{' '}
                                    -{' '}
                                    {
                                      joining.current_interview[0]
                                        ?.interview_stage.title
                                    }
                                  </td> */}
                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                    {joining.previous_interview[0]
                                      ?.interviewer_name || '-'}
                                  </td> */}
                                        {/* <td className="whitespace-nowrap text-center px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                                    {getRecomendCode(
                                      joining.previous_interview[0]
                                        ?.interview_recommendation
                                    ) || '-'}
                                  </td> */}
                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                    {joining.previous_interview[0]
                                      ?.interview_date || '-'}
                                  </td> */}
                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                    {joining.current_interview[0]
                                      ?.interview_date || '-'}
                                  </td> */}
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                          -
                                        </td>
                                        {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                    {joining.current_interview[0]
                                      ?.interviewer_name || '-'}
                                  </td> */}
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                          -
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                          <div className="flex justify-center">
                                            {getJoiningStatus(
                                              joining?.joining_approval_status,
                                              joining?.negotiation_approval_status,
                                              joining?.candidate_approval_status
                                            )}
                                          </div>
                                        </td>
                                        <td className="relative flex items-center justify-start whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                          <PencilSquareIcon
                                            className="ml-1 mr-2 h-7 w-7 text-purple-500 cursor-pointer "
                                            aria-hidden="true"
                                            // onClick={() =>
                                            //   gotoOfferDetails(
                                            //     'joiningdetails',
                                            //     joining
                                            //   )
                                            // }
                                            // onClick={() =>
                                            //   gotoJoiningDetails(
                                            //     'joiningdetails'
                                            //   )
                                            // }
                                            onClick={() =>
                                              gotoJoiningDetails(
                                                'joiningdetails',
                                                joining
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  <tr>
                                    <td
                                      colSpan={12}
                                      className="px-6 py-4  whitespace-nowrap text-sm text-black text-center font-semibold"
                                    >
                                      No Data Found
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    );
  }
}

export default JobCandidateList;
