import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowUpIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  PlusIcon,
  PaperClipIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import ModalData from '../../../../../components/common/ModalData';

import CandDetails from './CandDetails';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import {
  useCandJoiningDetailsQuery,
  useJoiningApprovalMutation,
  useJoiningNegotationMutation,
} from '../../../../../features/hiringstages/joiningApiSlice';
import { useGetNewFollowupQstnQuery, useGetNewQstnScoreQuery } from '../../../../../features/hiringstages/joiningApiSlice';
import CandCalling from './modal/CandCalling';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const JoiningDetails = ({ toggleJoining }) => {
  const [joiningComments, setJoiningComments] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const candId = useSelector(selectCandidateInterview);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const [joiningApproval, { isLoading: approvalLoading }] =
    useJoiningApprovalMutation();
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useCandJoiningDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  const [joiningNegotation, { isLoading: joiningNegotationLoading }] =
    useJoiningNegotationMutation();

  const canddetailsObj = canddetails?.data;
  const candJoingingArray = canddetailsObj?.joinings;
  const candJoingingReverse = canddetailsObj?.joinings?.slice().reverse();
  console.log(candJoingingReverse);
  const {data: getNewQstn, refetch: refetchNewFollowupQstn} = useGetNewFollowupQstnQuery(candId);
  console.log(getNewQstn);
  const newQuestion = getNewQstn?.data;
  console.log(newQuestion);
  const { data:newFollowupScore, refetch:refetchNewQstnScore} = useGetNewQstnScoreQuery(candId,{
    refetchOnMountOrArgChange: true,
  });
  console.log(newFollowupScore);
  const transformFollowupScore = (newFollowupScore) => {
    if (!newFollowupScore || !newFollowupScore.data || !newFollowupScore.data[0]) {
      // Return an empty array or handle the absence of data as needed
      return [];
    }
    const levels = {};
  
    newFollowupScore.data[0].forEach((item) => {
      const { calling_level, calling_score, call_questions } = item;
  
      if (!levels[calling_level]) {
        levels[calling_level] = { level: calling_level };
      }
  
      // Use the question text as a key, formatting it to be a valid object key
      // const questionKey = call_questions.toLowerCase().replace(/[^a-z0-9]/g, '_');
      
      // Add the score to the corresponding question key
      levels[calling_level][call_questions] = calling_score;
    });
  
    // Convert levels object into an array
    return Object.values(levels);
  };
  
  // // Example usage
  const transformedData = transformFollowupScore(newFollowupScore);
  console.log(transformedData);

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
      role: 'Member',
    },
    // More people...
  ];

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const gotoJoiningList = (value, candid) => {
    toggleJoining(value);
  };

  const handleClick = async (btnStatus) => {
    const initialValues = {
      profile_id: candId,
      joining_approval_status: 0,
      joining_comments: joiningComments,
      joining_priority: '',
    };

    let newData;
    if (btnStatus === 'Query') {
      newData = {
        ...initialValues,
        joining_approval_status: 3,
      };
    }
    if (btnStatus === 'Reject') {
      newData = {
        ...initialValues,
        joining_approval_status: 2,
      };
    }
    if (btnStatus === 'Approval') {
      newData = {
        ...initialValues,
        joining_approval_status: 1,
      };
    }
    try {
      const joiningApprovalData = await joiningApproval({
        ...newData,
      }).unwrap();
      alert(`Data ${btnStatus} successfully`);
    } catch (error) {
      alert('Error');
    }
  };
  const getOverallScore = (data) => {
    const staticEntries = [
      data?.interview_with_other_company,
      data?.other_offers_in_hand,
      data?.attitude,
      data?.response_to_communication,
      data?.knowledge_about_company,
      data?.interest_towards_company,
      data?.satisfaction_towards_CTC,
      data?.satisfaction_towards_job_role,
      data?.interest_to_involve_with_internal_teams,
      data?.location_satisfaction,
    ];
  
    const staticScoreData = staticEntries.reduce(
      (acc, value) => {
        const num = JSON.parse(value || 0); // Parse value or use 0 if undefined
        acc.sum += num;
        acc.count += 1;
        return acc;
      },
      { sum: 0, count: 0 }
    );
  
    // Calculate static average by dividing sum by count, if count > 0
    return staticScoreData.count > 0 ? staticScoreData.sum : 0;
  };
  
  const getTotalCombinedScore = (joining, transformedData) => {
    // Calculate static score using getOverallScore
    const staticScore = getOverallScore(joining);
    const staticCount = 10; // Since we always expect 10 fields in getOverallScore
  
    console.log("Static Score:", staticScore);
  
    // Check if transformedData is empty and return staticScore if so
    if (transformedData.length === 0) {
      return staticScore *10 / staticCount;
    }
  
    // Find matching dynamicScoreEntry in transformedData based on level
    const dynamicScoreEntry = transformedData.find(item => item.level === joining.level);
    console.log("Dynamic Score Entry:", dynamicScoreEntry);
  
    // Calculate dynamic score and count
    const dynamicScoreData = dynamicScoreEntry && typeof dynamicScoreEntry === 'object'
      ? Object.entries(dynamicScoreEntry).reduce(
          (acc, [key, val]) => {
            if (key !== 'level' && typeof val === 'number') {
              acc.sum += val;
              acc.count += 1;
            }
            return acc;
          },
          { sum: 0, count: 0 }
        )
      : { sum: 0, count: 0 };
  
    console.log("Dynamic Score:", dynamicScoreData.sum, "Count:", dynamicScoreData.count);
  
    // Calculate total combined score and count
    const totalSum = staticScore + dynamicScoreData.sum;
    const totalCount = staticCount + dynamicScoreData.count;
  
    // Calculate combined average
    const combinedScore = totalCount > 0 ? (totalSum / totalCount)*10 : 0 ;
    console.log("Combined Score:", combinedScore);
  
    return combinedScore;
  };

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    negotiated_doj: canddetailsObj?.negotiated_doj || '',
    // negotiated_salary: canddetailsObj?.negotiated_salary || '',
    negotiated_salary: canddetailsObj?.negotiated_salary || '6000',
    negotiated_notice_period: canddetailsObj?.negotiated_notice_period || '',
    payout_option_required: canddetailsObj?.payout_option_required || '',
    payout_amount: canddetailsObj?.payout_amount || '',
    negotiation_comments: canddetailsObj?.negotiation_comments || '',
  };

  const validationSchema = yup.object({
    negotiated_salary: yup.string().required('Please enter negotiate salary'),
    negotiated_doj: yup.string().required('Please select the date of joining'),
    negotiated_notice_period: yup
      .string()
      .required('please enter the notice period'),
    payout_option_required: yup
      .string()
      .required('Please select the Buyout option '),
    payout_amount: yup.string().required('Please enter the Buyout amount'),
    negotiation_comments: yup.string().required('Please enter the Comments'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const rrrr = await joiningNegotation(validationSchema).unwrap();
      alert('Negotation sumbitted Successfully');
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const getPrediction = () => {
    let predictionScore = 0;
    candJoingingReverse?.length > 0 &&
      candJoingingReverse.map((joining) => {
        // predictionScore = getOverallScore(joining);
        predictionScore = getTotalCombinedScore(joining, transformedData)
      });
    // return Math.round(predictionScore);

  const roundedScore = Math.round(predictionScore);

  let status;
  if (roundedScore < 50) {
    status = "At Risk";
  } else if (roundedScore >= 50 && roundedScore <= 59) {
    status = "Suspectable";
  } else if (roundedScore >= 60 && roundedScore <= 69) {
    status = "Unpredictable";
  } else if (roundedScore >= 70) {
    status = "On Track";
  }
  return { roundedScore, status };
  };

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoJoiningList('joininglist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails canddetails={canddetails} />

        <div className="basis-5/6">
          <div className="flex flex-row  ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
              <div className="bg-white ">
                  <div className="px-6 py-4">
                    <div className="">
                      <div className="">
                        <div className="inline-block min-w-full py-0 align-middle bg-white ">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="border-y border-gray-300">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Factors
                              </th>
                              {candJoingingReverse?.length > 0 &&
                                candJoingingReverse.map((joining) => (
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-md font-semibold text-gray-900"
                                  >
                                    {`Level: ${joining?.level} Calling Score`}
                                  </th>
                                ))}
                              {/* <h1 className="  font-semibold px-0 text-xl mt-3 text-gray-700 pb-4">
                                Basic Skill
                              </h1> */}
                            </thead>
                            <tbody>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interview with other company
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interview_with_other_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Other offers in hand
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.other_offers_in_hand * 10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Attitude
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${joining?.attitude * 10}%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Response to communication
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.response_to_communication *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Knowledge about company
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.knowledge_about_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest towards company
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interest_towards_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards CTC
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.satisfaction_towards_CTC *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards Job Role
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.satisfaction_towards_job_role *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest to involve with internal teams
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interest_to_involve_with_internal_teams *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Location Satisfaction
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.location_satisfaction * 10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              
  {/* {Array.isArray(newQuestion) && newQuestion.length > 0 ? (
    newQuestion.map((question) => (
      <th
        key={question.id}
        scope="col"
        className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
      >
        {question.call_questions}
      </th>
      // {transformedData?.map((qstnScore) => (
      //   <tr key={qstnScore.level}>
      //     <td className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0">
      //       Level {data.level}
      //     </td>
      //     {newQuestion.map((question) => (
      //       <td
      //         key={question.id}
      //         className="py-3.5 pl-4 pr-3 text-sm text-gray-900 sm:pl-0"
      //       >
      //         {qstnScore[question.call_questions] !== undefined
      //           ? `${qstnScore[question.call_questions]}%`
      //           : '-'}
      //       </td>
      //     ))}
      //   </tr>
      // ))}
      // {transformedData?.length>0 &&
      //   transformedData.map((followupScore,i) => (
      //     <th
      //                                 scope="col"
      //                                 className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
      //                               >
      //                                 {followupScore != null
      //                                 ? }
      //                               </th>
      //   ))
      // }
    ))
  ) : (
    <th
      scope="col"
      className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
    >
      No Questions Available
    </th>

  )} */}
  <p className='text-left text-xl font-bold text-gray-900 sm:pl-0'>New Question</p>

{Array.isArray(newQuestion) && newQuestion.length > 0 ? (
  newQuestion.map((question) => (
    <tr key={question.id}>
      <th
        scope="col"
        className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
      >
        {question.call_questions}
      </th>
      {transformedData.map((qstnScore, i) => (
        <th
          key={i}
          scope="col"
          className="py-3.5 pl-4 pr-3 text-sm text-gray-900 sm:pl-0"
        >
          {qstnScore[question.call_questions] !== undefined
            ? `${qstnScore[question.call_questions]*10}%`
            : '-'}
        </th>
      ))}
    </tr>
  ))
) : (
  <tr>
    <th
      scope="col"
      className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
    >
      {/* No Questions Available */}
    </th>
  </tr>
)}

                              <tr className="border-t border-gray-300">
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-0"
                                >
                                  Overall Score:
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 text-sidebar text-xl font-semibold pl-4 pr-3  sm:pl-0 text-center"
                                    >
                                      {/* {joining
                                        ? `${getOverallScore(joining)}%`
                                        : '-'} */}
                                        {joining ? `${getTotalCombinedScore(joining, transformedData).toFixed(1)}%` : '-'}
                                    </th>
                                  ))}
                              </tr>
                              
        
    
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white flex justify-start py-4 px-10">
                  <span className="text-xl font-bold">
                    Current Joining Status :
                    <b className="text-blue-600 text-xl font-semibold">
                      {/* {getPrediction()}  */}
                      {/* Unpredictable */}
                      {`${getPrediction().roundedScore}  -   ${getPrediction().status}`}
                    </b>
                  </span>
                </div>

                <form
                  className="  border-t border-gray-300 py-4 mx-6"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="">
                    <div className="">
                      <div className=" rounded-md shadow-3xl mx-0">
                        {' '}
                        <div className="mt-0 grid grid-cols-3 gap-6 px-0">
                          <div className="grid grid-cols-2">
                            <div className="mt-0">
                              <label
                                htmlFor="expected_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Expected Salary :
                              </label>
                            </div>
                            <div>
                              <h5 className="mt-0 font-bold text-sm read-only">
                                {canddetailsObj?.salary_expectation}
                              </h5>
                            </div>
                          </div>

                          <div className="grid grid-cols-2">
                            <div className="mt-0 ">
                              <label
                                htmlFor="negotiated_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated Salary :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_salary}
                              </h4>
                            </div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="mt-0 ">
                              <label
                                htmlFor="negotiated_DOJ"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated DOJ :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_doj}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="mt-0 grid grid-cols-3 gap-6 px-0">
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mt-0 mr-6 ">
                              <label
                                htmlFor="notice_period"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Notice Period :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_notice_period}
                              </h4>
                            </div>
                          </div>{' '}
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mt-0 ">
                              <label
                                htmlFor="buyout_option"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Option :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_notice_period}
                              </h4>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mr-5">
                              <label
                                htmlFor="buyout_amount"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Amount :
                              </label>
                            </div>
                            <h4 className="mt-0 font-bold text-sm">
                              {canddetailsObj?.payout_amount}
                            </h4>
                          </div>
                        </div>
                        <div className="mx-0 mt-4 grid grid-cols-1 gap-6 px-0 border-t border-gray-300 pt-2">
                          <div>
                            <label
                              htmlFor="comments"
                              className="block text-md font-semibold text-gray-700 pb-1"
                            >
                              Comments
                            </label>

                            <div>
                              <p className="mt-1 font-medium text-sm text-gray-500">
                                {canddetailsObj?.negotiation_comments}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mx-0 mt-3 grid grid-cols-1 gap-6 px-0 border-t border-gray-300 pt-2">
                          <div>
                            <label
                              htmlFor="feedback"
                              className="block text-md font-semibold text-gray-700 pb-2"
                            >
                              Feedback
                            </label>

                            <textarea
                              rows={5}
                              name="comments"
                              id="comments"
                              className="bg-inputbg w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder=""
                              value={joiningComments}
                              onChange={(e) =>
                                setJoiningComments(e.target.value)
                              }
                              // defaultValue={''}
                              // onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              // value={formik.values.feedback}
                            />
                          </div>
                        </div>
                        <div className="mx-0 mt-0 w-full">
                          <div className="mt-4 space-x-8 w-full flex justify-start">
                            {/* <div>
                              {' '}
                              <button
                                type="button"
                                className=" rounded-lg  bg-queryBlue text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                                // onClick={onClose}
                              >
                                Query
                              </button>
                            </div> */}
                            <button
                              type="button"
                              className=" rounded-lg  bg-blue-500 hover:bg-blue-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Query')}
                            >
                              Query
                            </button>
                            <button
                              type="button"
                              className=" rounded-lg  bg-red-600 hover:bg-red-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Reject')}
                            >
                              Reject
                            </button>

                            <button
                              type="button"
                              className=" rounded-lg  bg-green-400 hover:bg-green-600 text-white py-2 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Approval')}
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        // data={openModalDataValues}
      >
        {/* <EditJD /> */}
        {/* <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        /> */}

        {/* Modal window */}
        <CandCalling dataval={openModalDataValues} />
        {/* Modal window */}
      </ModalData>
    </>
  );
};
export default JoiningDetails;
