import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

// Core viewer
// import { Viewer } from '@react-pdf-viewer/core';

// // // Plugins
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// // // Import styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function CandResume({ resume }) {
  resume = '/resume/static-resume.pdf'

  // const docs = [{
  //   uri: "/resume/static-resume.pdf",
  //   fileType: "pdf",
  //   fileName: "static-resume.pdf"
  // }]

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  console.log('resume = ', resume);
  return resume != null ? (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          height: '750px',
        }}
      >
        <Viewer fileUrl={resume} />
      </div>
    </Worker>
    // <DocViewer
    //   documents={docs}
    //   pluginRenderers={DocViewerRenderers}
    //   style={{ height: 1000 }}
    // />
  ) : (
    // <Viewer
    //   fileUrl={resume}
    //   plugins={[
    //     // Register plugins
    //     defaultLayoutPluginInstance,
    //   ]}
    // />
    'No resume found'
  );
}

export default CandResume;
