import { PlusIcon } from '@heroicons/react/24/outline';
import JobDesc from './jobdescription/JobDesc';
import SkillCapture from './skillcapture/SkillCapture';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import Sourcing from './sourcing/Sourcing';
import { AddSourcing } from './sourcing/AddSourcing';
import { AddCandidate } from './sourcing/AddCandidate';

import { useDispatch } from 'react-redux';
import { setSourcing } from '../../../../features/hiringstages/sourcingSlice';
import { setScreening } from '../../../../features/hiringstages/screeningSlice';

// import Screening from '../../signoff/hiringstage/screening/Screening';
// import Screening from './screening/Screening_old';
import { ViewCandidate } from './screening/ViewCandidate';

// import Interview from './interview/Interview';
import InterviewProcess from './interview/InterviewProcess';
import Offer from './offer/Offer';
import OfferDetails from './offer/OfferDetails';
import InterviewRequest from './interview/InterviewRequest';
import Screening from './screening/Screening';
import CandidateList from './screening/CandidateList';
import InterviewCandidateList from './interview/InterviewCandidateList';
import Interview from './interview/Interview';
import Joining from './joining/Joining';
import JoiningDetails from './joining/JoininjDetails';
import Handover from './handover/Handover';
import HandoverDetails from './handover/HandoverDetails';
import {
  updateLevelThree,
  levelThree,
} from '../../../../features/privileges/userWorkspaceSlice';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MTHiringStage() {
  const levelthree = useSelector(levelThree);
  const [levelThreeState, setLevelThreeState] = useState(0);

  const [sourcingList, setSourcingList] = useState(true);
  const [addSourcing, setAddSourcing] = useState(false);
  const [addCandidate, setAddCandidate] = useState(false);
  // const [transactionsList, setTransactionsList] = useState(true);

  const [screeningList, setScreeningList] = useState(true);
  const [candidateList, setCandidateList] = useState(false);
  const [viewCandidate, setViewCandidate] = useState(false);

  const [interviewList, setInterviewList] = useState(true);
  const [interviewProcess, setInterviewProcess] = useState(false);

  const [offerList, setOfferList] = useState(true);
  // const [transactionList, setTransactionList] = useState(true);
  const [offerDetails, setOfferDetails] = useState(false);

  const [joiningList, setJoiningList] = useState(true);
  // const [transactionList, setTransactionList] = useState(true);
  const [joiningDetails, setJoiningDetails] = useState(false);

  const [handoverList, setHandoverList] = useState(true);
  // const [transactionList, setTransactionList] = useState(true);
  const [handoverDetails, setHandoverDetails] = useState(false);

  const dispatch = useDispatch();

  const setSourcingToggle = (value, obj) => {
    if (value === 'addsourcing') {
      setSourcingList(false);
      setAddSourcing(true);
      dispatch(setSourcing(obj));
    }

    if (value === 'sourcinglist') {
      setSourcingList(true);
      setAddSourcing(false);
    }

    if (value === 'addcandidate') {
      setAddSourcing(false);
      setAddCandidate(true);
    }

    if (value === 'cancelcandidate') {
      setAddSourcing(true);
      setAddCandidate(false);
    }
  };

  const setScreeningToggle = (value, obj) => {
    if (value === 'screeninglist') {
      setScreeningList(true);
      setCandidateList(false);
      setViewCandidate(false);
    }

    if (value === 'candidatelist') {
      setScreeningList(false);
      setCandidateList(true);
      setViewCandidate(false);
      dispatch(setScreening(obj));
    }

    if (value === 'viewCandidate') {
      setScreeningList(false);
      setCandidateList(false);
      setViewCandidate(true);
    }
  };

  const setInterviewToggle = (value, obj) => {
    if (value === 'interviewlist') {
      setInterviewList(true);
      setInterviewProcess(false);
      // setViewCandidate(false);
    }

    if (value === 'interviewprocess') {
      setInterviewList(false);
      setInterviewProcess(true);
      // setViewCandidate(false);
      // dispatch(setScreening(obj));
    }

    // if (value === 'viewCandidate') {
    //   setScreeningList(false);
    //   setCandidateList(false);
    //   setViewCandidate(true);
    // }
  };

  const setOfferToggle = (value, obj) => {
    if (value === 'offerlist') {
      setOfferList(true);
      setOfferDetails(false);
      // setViewCandidate(false);
    }

    if (value === 'offerdetails') {
      setOfferList(false);
      setOfferDetails(true);
      // setViewCandidate(false);
      // dispatch(setScreening(obj));
    }

    // if (value === 'viewCandidate') {
    //   setScreeningList(false);
    //   setCandidateList(false);
    //   setViewCandidate(true);
    // }
  };

  const setJoiningToggle = (value, obj) => {
    if (value === 'joininglist') {
      setJoiningList(true);
      setJoiningDetails(false);
      // setViewCandidate(false);
    }

    if (value === 'joiningdetails') {
      setJoiningList(false);
      setJoiningDetails(true);
      // setViewCandidate(false);
      // dispatch(setScreening(obj));
    }

    // if (value === 'viewCandidate') {
    //   setScreeningList(false);
    //   setCandidateList(false);
    //   setViewCandidate(true);
    // }
  };

  const setHandoverToggle = (value, obj) => {
    if (value === 'handoverlist') {
      setHandoverList(true);
      setHandoverDetails(false);
      // setViewCandidate(false);
    }

    if (value === 'handoverdetails') {
      setHandoverList(false);
      setHandoverDetails(true);
      // setViewCandidate(false);
      // dispatch(setScreening(obj));
    }

    // if (value === 'viewCandidate') {
    //   setScreeningList(false);
    //   setCandidateList(false);
    //   setViewCandidate(true);
    // }
  };
  const handleLevelThree = (index) => {
    dispatch(updateLevelThree(index));
  };

  useEffect(() => {
    setLevelThreeState(levelthree);
  }, [levelthree]);

  return (
    <>
      <Tab.Group
        as="div"
        className=""
        selectedIndex={levelThreeState}
        onChange={handleLevelThree}
      >
        <Tab.List className="rounded-lg  bg-white shadow-lg sm:grid sm:grid-cols-8 text-md font-semibold uppercase">
          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 focus:outline-none bg-gray-100 text-indigo-600 rounded-l-lg '
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap   py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-job_desc p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Job Desc</dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">98</dd> */}
            </div>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100 text-indigo-600 focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-skill_capture p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">
                Skill Capture
              </dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">98</dd> */}
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100  text-indigo-600 focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0 text-sm font-bold px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-sourcing p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Sourcing</dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">98</dd> */}
            </div>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100 text-indigo-600 focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-screening p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Screening</dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">80</dd> */}
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100 text-indigo-600 focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0 px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-interview p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Interview</dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">70</dd> */}
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 focus:outline-none bg-gray-100 text-indigo-600 '
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r  border-t-offer p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Offer </dt>
              {/* offer */}
              {/* <dd className="order-1  tracking-tight text-gray-600">50</dd> */}
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100 text-indigo-600 focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-r border-t-joining p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Followup</dt>
              {/* joining */}
              {/* <dd className="order-1  tracking-tight text-gray-600">25</dd> */}
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-indigo-500 bg-gray-100 text-indigo-600 rounded-r-lg focus:outline-none'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                // 'whitespace-nowrap border-b-2 py-0  px-0'
              )
            }
          >
            <div className="flex flex-col border-t-4 border-t-handover p-4 text-left ">
              <dt className="order-2 mt-2  leading-6 text-black">Onboarding</dt>
              {/* <dd className="order-1  tracking-tight text-gray-600">23</dd> //handover*/}
            </div>
          </Tab>
        </Tab.List>

        <Tab.Panels as={Fragment}>
          <Tab.Panel className="space-y-16 py-6">
            {/* Content MYTASK */}
            <JobDesc />
          </Tab.Panel>
          <Tab.Panel className="space-y-16 py-6">
            <SkillCapture />
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-6">
            {sourcingList ? (
              <Sourcing toggleSourcing={setSourcingToggle} />
            ) : (
              ''
            )}
            {addSourcing ? (
              <AddSourcing toggleSourcing={setSourcingToggle} />
            ) : (
              ''
            )}
            {addCandidate ? (
              <AddCandidate toggleSourcing={setSourcingToggle} />
            ) : (
              ''
            )}
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-6">
            {screeningList ? (
              <Screening toggleScreening={setScreeningToggle} />
            ) : (
              ''
            )}
            {candidateList ? (
              <CandidateList toggleScreening={setScreeningToggle} />
            ) : (
              ''
            )}

            {viewCandidate ? (
              <ViewCandidate toggleScreening={setScreeningToggle} />
            ) : (
              ''
            )}
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-5">
            {/* Content Interview */}
            {/* <Interview /> */}

            {interviewList ? (
              <Interview toggleInterview={setInterviewToggle} />
            ) : (
              ''
            )}
            {interviewProcess ? (
              <InterviewProcess toggleInterview={setInterviewToggle} />
            ) : (
              ''
            )}

            {/* <InterviewProcess />s */}
            {/* <InterviewRequest /> */}
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-6">
            {/* <Offer />
            <OfferDetails /> */}

            {offerList ? <Offer toggleOffer={setOfferToggle} /> : ''}
            {offerDetails ? <OfferDetails toggleOffer={setOfferToggle} /> : ''}
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-6">
            {/* Content Joining */}
            {joiningList ? <Joining toggleJoining={setJoiningToggle} /> : ''}
            {joiningDetails ? (
              <JoiningDetails toggleJoining={setJoiningToggle} />
            ) : (
              ''
            )}
            {/* <Joining />
            <JoiningDetails /> */}
          </Tab.Panel>
          <Tab.Panel className="space-y-4 py-6">
            {/* Content Handover */}

            {handoverList ? (
              <Handover toggleHandover={setHandoverToggle} />
            ) : (
              ''
            )}
            {handoverDetails ? (
              <HandoverDetails toggleHandover={setHandoverToggle} />
            ) : (
              ''
            )}
            {/* <Handover />
            <HandoverDetails /> */}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}
