import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import candidateInterviewSlice from '../../../../../../features/hiringstages/candidateInterviewSlice';
// import { useNegotationMutation } from '../../../../../../features/hiringstages/offerApiSlice';
import { useNegotationMutation } from '../../../../../../features/hiringstages/offerApiSlice';
import Notification from '../../../../../../components/common/Notification';
const Negotation = ({ canddetails,toggleOffer }) => {
  const [showNotification, setShowNotification] = useState(false);
  let { id } = useParams();
  const canddetailsObj = canddetails?.data;
  const [negotation] = useNegotationMutation();
  console.log(canddetails)

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    // negotiated_salary: canddetailsObj?.negotiated_salary || '',
    negotiated_salary: canddetailsObj?.negotiated_salary || '60000',
    negotiated_doj: canddetailsObj?.negotiated_doj || '',
    negotiated_designation: canddetailsObj?.negotiated_designation || '',
    negotiated_notice_period: canddetailsObj?.negotiated_notice_period || '',
    payout_option_required: canddetailsObj?.payout_option_required || '',
    payout_amount: canddetailsObj?.payout_amount || '',
    negotiation_comments: canddetailsObj?.negotiation_comments || '',
    negotiation_priority: canddetailsObj?.negotiation_priority || 0,
    negotiation_approval_status: 2,
    negotiation_feedback: canddetailsObj?.negotiation_feedback || '',
  };

  const validationSchema = yup.object({
    negotiation_feedback: yup.string().required('please enter the feedback'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const rrrr = await negotation({
        ...validationSchema,
      }).unwrap();
      alert('Data Approve successfully ');
      toggleOffer('offerlist', 'offer')
    } catch (error) {
      alert('Error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleClick = async (btnStatus) => {
    let newData;
    if (btnStatus === 'Query') {
      newData = { ...formik.values, negotiation_approval_status: 4 };
    }
    if (btnStatus === 'Reject') {
      newData = { ...formik.values, negotiation_approval_status: 3 };
    }

    try {
      const rrrr = await negotation({
        ...newData,
      }).unwrap();

      alert(`Data ${btnStatus} successfully`);
      toggleOffer('offerlist', 'offer')
    } catch (error) {
      alert('Error');
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" bg-gray-200 py-4 rounded-lg px-4">
          <div className=" rounded-md shadow-3xl mx-0">
            {' '}
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2 mt-1">
                <div className="self-center">
                  <label
                    htmlFor="expected_salary"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Expected Salary
                  </label>
                </div>
                <div className="mt-3">
                  <span> {canddetails?.data.salary_expectation}</span>
                </div>
              </div>

              <div className="grid grid-cols-2">
                <div className="mt-3 ">
                  <label
                    htmlFor="negotiated_salary"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Negotiated Salary
                  </label>
                </div>
                <div className="mt-2">{canddetails?.data.negotiated_salary}</div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2 mt-2">
                <div className="self-center">
                  <label
                    htmlFor="negotiated_doj"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Negotiated DOJ
                  </label>
                </div>
                <div>{canddetails?.data.negotiated_doj}</div>
              </div>

              <div className="grid grid-cols-2 mt-2">
                <div className="mr-6 self-center">
                  <label
                    htmlFor="notice_period"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Notice Period
                  </label>
                </div>
                <div>{canddetails?.data.negotiated_notice_period} days</div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0 ">
              <div className="grid grid-cols-2 mt-2">
                <div className="mt-3 ">
                  <label
                    htmlFor="payout_option_required"
                    className="block text-sm  font-medium text-gray-900 mr-4"
                  >
                    Buyout Option
                  </label>
                </div>
                <div>{canddetails?.data.payout_option_required}</div>
              </div>

              <div className="grid grid-cols-2">
                <div className="mt-3 mr-5 ">
                  <label
                    htmlFor="payout_amount"
                    className="block text-sm  font-medium text-gray-900 mr-4 "
                  >
                    Buyout Amount
                  </label>
                </div>
                <div className="mt-2">{canddetails.data.payout_amount}</div>
              </div>
            </div>
            <div className="mt-0 grid grid-cols-2 gap-6 px-0">
              <div className="grid grid-cols-2">
                <div className="mt-3 mr-6  ">
                  <label
                    htmlFor="negotiated_designation"
                    className="block text-sm  font-medium text-gray-900 mr-4 "
                  >
                    Designation Negotiated
                  </label>
                </div>
                <div className="mt-2">
                  {canddetails?.data.negotiated_designation}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-0 my-3 w-full">
          <div>
            <label
              htmlFor="negotiation_comments"
              className="block text-sm font-semibold text-gray-700 pb-2"
            >
              Comments
            </label>
            {canddetails?.data.negotiation_comments}
          </div>
        </div>

        <div className="mx-0 my-3 w-full">
          <div>
            <label
              htmlFor="negotiation_comments"
              className="block text-sm font-semibold text-gray-700 pb-2"
            >
              Feedback
            </label>

            <textarea
              rows={5}
              name="negotiation_feedback"
              id="negotiation_feedback"
              className="bg-inputbg w-full appearance-none rounded-md border border-gray-300   px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="text here"
              defaultValue={''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.negotiation_feedback}
            />
            {formik.touched.negotiation_feedback &&
              formik.errors.negotiation_feedback ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.negotiation_feedback}
              </div>
            ) : null} 
          </div>
        </div>

        <div className="mx-0 mt-0 w-ful">
          <div className=" mt-0 space-x-5 w-full flex justify-end">
            <button
              type="button"
              className=" rounded-lg  bg-blue-500 hover:bg-blue-700 text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
              onClick={() => handleClick('Query')}
            >
              Query
            </button>
            <button
              type="button"
              className=" rounded-lg  bg-red-600 hover:bg-red-700 text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
              onClick={() => handleClick('Reject')}
            >
              Reject
            </button>

            <button
              type="submit"
              className=" rounded-lg  bg-green-400 hover:bg-green-600 text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
            >
              Approve
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Negotation;
