import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useCreateOfferTempMutation, useUpdateOfferTempMutation } from "../features/hiringstages/offerApiSlice";

export const AddOfferTemplate = ({ goBack, formData, setFormData }) => {
  // console.log(accountId);
  const [createOfferTemp] = useCreateOfferTempMutation();
  const [updateOfferTemp] = useUpdateOfferTempMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  // const [formData, setFormData] = useState({
  //   offerTitle: "",
  //   offerDescription: "", 
  // });

  const [components] = useState({
    candidateName: 'Candidate Name',
    designation: 'Designation',
    salary: 'Salary',
    joiningDate: 'Joining Date',
    signature: 'Signature'
  })

  //handle input change 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, //spread the existing state
      [name]: value, //Dynamically update the specific field
    });
  };
  // console.log(formData);
  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };
  const handleSubmit = async (e) => {
    // console.log("handlesubmit triggered");
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (formData.offerId) {
        //if offerId exists, update the offer template
        const updateTemp = await updateOfferTemp({ ...formData });
        showNotificationMsg();
      } else {
        //create a new template
        const createTemp = await createOfferTemp(formData);
        // alert('Template created successfully')
        showNotificationMsg();       
      }
      goBack()
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  //Drag start event handler handleDragStart
  const handleDragStart = (e, componentValue) => {
    e.dataTransfer.setData('text/plain', componentValue);
  };

  //Drop event handler for the textarea
  const handleDrop = (e) => {
    e.preventDefault();
    const draggedText = e.dataTransfer.getData('text/plain');
    //Insert the dragged text in [""] into the description
    const updatedDescription = `${formData.offerDescription}["${draggedText}"]`;
    setFormData({
      ...formData,
      offerDescription: updatedDescription,
    });
  };

  //prevent default dragover behavior to allow drop
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm font-bold leading-4 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
          {/* Offer Template */}
          {formData.offerId ? 'Edit Offer Template' : 'Offer Template'}
        </p>
        <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
          <Link
            type="button"
            onClick={goBack}
            className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </Link>
        </div>
      </div>
      <div className="flex w-full h-96">
        <div className="flex w-3/4 h-[500px] bg-white shadow-lg gap-4 ml-5 rounded-lg mt-5">
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="grid text-lg pl-6 pt-3 items-center text-gray-900">
              <label htmlFor="offer_title" className="block text-lg font-medium text-gray-900 px-2">
                Offer Template Title:
              </label>
              <input
                type="text"
                name="offerTitle"
                id="offer_title"
                className="bg-inputbg border-inputBorder mt-1 block w-72 text-lg rounded-md py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm mr-4 items-center"
                onChange={handleChange}
                value={formData.offerTitle || ''} //pre-fill if editing
                placeholder="Offer Template Title"
              />
            </div>
            <div className="grid text-lg px-6 pt-3 items-center text-gray-900">
              <label htmlFor="offer_description" className="block text-lg font-medium text-gray-900 px-2">
                Offer Template Description:
              </label>
              <textarea
                id="offer_description"
                name="offerDescription"
                rows="15"
                className="bg-inputbg border border-inputBorder block w-full text-lg rounded-md py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                placeholder="Enter description here"
                onChange={handleChange}
                value={formData.offerDescription || ''} //pre-fill if editing
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              />
            </div>
            <div className="flex justify-end my-3 mx-6">
              <button
                type="submit"
                to={''}
                className="flex justify-end rounded-3xl border border-transparent bg-violet-900 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                disabled={isSubmitting}
              >
                {/* submit  */}
                {formData.offerId ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        </div>

        <div className="p-2"></div>

        <div className="flex w-1/4  bg-white shadow-lg gap-2 ml-5 rounded-lg mt-5">
          <div className="grid grid-cols-3 gap-4 my-5 place-items-center w-full">
            <input
              className="bg-inputbg p-4 col-span-3 text-lg font-semibold overflow-auto rounded-md shadow-lg h-10 text-gray-800 w-48"
              value="Candidate Name" disabled draggable // Make it draggable
              onDragStart={(e) => handleDragStart(e, components.candidateName)} // Handle drag start
            />
            <input
              className="bg-inputbg p-4 col-span-3 text-lg font-semibold overflow-auto rounded-md shadow-lg h-10 text-gray-800 w-48"
              value="Designation" disabled draggable // Make it draggable
              onDragStart={(e) => handleDragStart(e, components.designation)} // Handle drag start
            />
            <input
              className="bg-inputbg p-4 col-span-3 text-lg font-semibold overflow-auto rounded-md shadow-lg h-10 text-gray-800 w-48"
              value="Salary" disabled draggable // Make it draggable
              onDragStart={(e) => handleDragStart(e, components.salary)} // Handle drag start
            />
            <input
              className="bg-inputbg p-4 col-span-3 text-lg font-semibold overflow-auto rounded-md shadow-lg h-10 text-gray-800 w-48"
              value="Joining Date" disabled draggable // Make it draggable
              onDragStart={(e) => handleDragStart(e, components.joiningDate)} // Handle drag start
            />
            <input
              className="bg-inputbg p-4 col-span-3 text-lg font-semibold overflow-auto rounded-md shadow-lg h-10 text-gray-800 w-48"
              value="Signature" disabled draggable // Make it draggable
              onDragStart={(e) => handleDragStart(e, components.signature)} // Handle drag start
            />
          </div>
        </div>
      </div>
    </>
  )
}