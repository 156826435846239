import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAccountApiMutation,
  useAccountQuery,
} from '../features/account/accountApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Notification from '../components/common/Notification';
//added by lakshmi
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon, TrashIcon, EyeIcon } from '@heroicons/react/24/outline';
import { AddOfferTemplate } from './AddOfferTemplate';
import { useGetOfferTempQuery, useDeleteOfferTempMutation } from '../features/hiringstages/offerApiSlice';
import Modal from '../components/common/Modal';
//end here 

const OfferTemplate = () => {
  const [accountApi] = useAccountApiMutation();
  const [showNotification, setShowNotification] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const { data: accountQuery } = useAccountQuery({ refetchOnMountOrArgChange: true });
  // console.log(accountQuery);
  const [deleteTemp] = useDeleteOfferTempMutation();
  const { data: getOfferTemplate,
    isLoading,
    isError } =
    useGetOfferTempQuery({ refetchOnMountOrArgChange: true });
  console.log(getOfferTemplate);
  const [offerId, setOfferId] = useState();
  const [openModal, setOpenModal] = useState(false);

  // // useEffect to handle side effects when data is fetched
  // useEffect(() => {
  //   if (getOfferTemplate) {
  //     console.log("Offer templates fetched successfully:", getOfferTemplate);
  //   }
  // }, [getOfferTemplate]); // Only runs when getOfferTemplate changes

  // if (isLoading) return <p>Loading...</p>;
  // if (isError) return <p>Something went wrong...</p>;

  const [isAddingTemplate, setIsAddingTemplate] = useState(false); // State to manage views
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedTemp, setSelectedTemp] = useState(null);
  const [formData, setFormData] = useState({
    offerId: '',
    offerTitle: '',
    offerDescription: '',
  });

  const toggleAddTemplate = () => {
    setIsAddingTemplate(!isAddingTemplate);
    setFormData({
      offerId: '',
      offerTitle: '',
      offerDescription: '',
    });
  };
  const handleEdit = (offerTemp) => {
    setIsAddingTemplate(true); // Switch to Add template mode
    setFormData({
      offerId: offerTemp.id, //pass the id for editing
      offerTitle: offerTemp.template_title,
      offerDescription: offerTemp.description,
    })
  };
  //fn to open the modal and set the selected template data
  const handleView = (offerTemp) => {
    setSelectedTemp(offerTemp);
    setIsModelOpen(true);
  }
  //fn to close the modal
  const handleCloseModal = () => {
    setIsModelOpen(false);
    setSelectedTemp(null);
  }
  //function for delete the template data
  // const handleDelete = async (offerTemp) => {
  //   try {
  //     console.log(offerTemp);
  //     const deleteOfferTemp = await deleteTemp(offerTemp); // Assume deleteTemp is the function to delete
  //     console.log(deleteOfferTemp); // Handle the response or UI update as needed
  //   } catch (error) {
  //     console.error('Error deleting offer template:', error);
  //   }
  // };
  const openModalWindow = (id) => {
    setOfferId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal)
  };
  const onModalSubmit = async (offerId) => {
    try {
      const deleteOfferTemp = await deleteTemp(offerId).unwrap();
      setOpenModal(!openModal)
      showNotificationMsg();
    } catch (error) {
      console.error('Failed to delete template', error)
    }
  }
  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };
  return (
    <>
      {!isAddingTemplate ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <p className="text-sm font-bold leading-4 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
              Offer Template
            </p>
            <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
              <Link
                type="button"
                to={'..'}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
              >
                <ArrowLeftIcon
                  className="-ml-1 mr-2 h-5 w-5 text"
                  aria-hidden="true"
                />
                Back
              </Link>
            </div>
          </div>


          <div className="flex justify-end mt-6">
            <div>
              <button
                type="button"
                to={''}
                className="flex justify-end rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                onClick={toggleAddTemplate} // Trigger state change
              >
                <PlusIcon
                  className="-ml-1 mr-2 h-5 w-5 text"
                  aria-hidden="true"
                // onClick={toggleAddTemplate}
                />
                Create Offer Template
              </button>
            </div>
          </div>

          <div className="basis-5/6  gap-4 mt-6">
            <div className="bg-white shadow-lg gap-4 ml-5 rounded-lg mt-5">

              <div className="m-10 flex flex-col px-6 py-4">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-2">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100 text-md font-bold text-gray-900">
                          {/* <tr>
                      {tableHeader.map((headData, i) => (
                        <th
                          scope="col"
                          key={i}
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left  sm:pl-6"
                        >
                          {headData}
                        </th>
                      ))}
                    </tr> */}
                          <tr>
                            <th scope='col' className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left  sm:pl-4 w-3/4">
                              Offer Template Title
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 pl-4 w-1/4 "
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white ">
                          {getOfferTemplate?.viewOfferLtrTemp
                            // .filter((data) => data.status == 1)
                            .map((offerTemp) => (
                              <tr key={offerTemp.id}>
                                <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm  sm:pl-6 border-r-2'>
                                  {offerTemp.template_title}
                                </td>
                                <td className="relative flex items-center justify-start whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">

                                  <PencilSquareIcon
                                    className="ml-1 mr-2 h-7 w-7 text-purple-500 cursor-pointer "
                                    aria-hidden="true"
                                    onClick={() => handleEdit(offerTemp)}
                                  />
                                  <TrashIcon
                                    className="h-5 w-5 mr-2 cursor-pointer text-red-600"
                                    aria-hidden="true"
                                    // onClick={() => handleDelete(offerTemp)} // Ensure offerTemp is correctly passed
                                    onClick={() => openModalWindow(offerTemp.id)}
                                  />
                                  <EyeIcon
                                    className="ml-1 mr-2 h-7 w-7 text-purple-500"
                                    aria-hidden="true"
                                    onClick={() => handleView(offerTemp)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* Modal component */}
            {/* {isModelOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-1/2 p-6 relative">
          <h2 className="text-2xl font-semibold mb-4">View Offer Template</h2>
          <p><strong>Offer Template Title :</strong> {selectedTemp?.template_title}</p>
          <p><strong>Offer Template Description :</strong></p>
          <div className='h-[400px]' dangerouslySetInnerHTML={{
      __html: selectedTemp?.description.replace(/\n/g, "<br/>")
    }}/>
          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            
          </div>
        </div>
      </div>
      )} */}
            {isModelOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg w-1/2 p-6 relative">
                  <h2 className="text-2xl font-semibold mb-4">View Offer Template</h2>

                  <p><strong>Offer Template Title :</strong> </p>
                  <div className='px-4 py-2 border rounded-md bg-gray-100 w-2/4'>
                    {selectedTemp?.template_title}
                  </div>

                  <p><strong>Offer Template Description :</strong></p>

                  {/* Scrollable Description */}
                  <div className="h-[340px] overflow-y-auto p-4 border rounded-md bg-gray-100">
                    <div
                      className="whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html: selectedTemp?.description.replace(/\n/g, "<br/>")
                      }}
                    />
                  </div>

                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <AddOfferTemplate goBack={toggleAddTemplate} formData={formData}
          setFormData={setFormData} />
      )}
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(offerId)}
      />
      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
    </>
  );
};

export default OfferTemplate;
