import React from 'react';
import { Disclosure } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setCandId } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useCandHandoverlistQuery } from '../../../../../features/hiringstages/handoverApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';

function HandoverCandidateList({ toggleHandover, JobRequestId }) {
  const dispatch = useDispatch();
  const {
    data: handovercandList,
    error: handovercandListError,
    isLoading: handovercandListLoading,
    isFetching: handovercandListFetching,
    isSuccess: handovercandListSuccess,
  } = useCandHandoverlistQuery(JobRequestId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const handovercandListObj = handovercandList?.data;
  console.log(JobRequestId, handovercandListObj)

  const gotoHandoverDetails = (value, obj) => {
    if (obj?.id > 0) {
      dispatch(setCandId(obj.id));
    } else {
      dispatch(setCandId(0));
    }
    toggleHandover(value, obj);
  };
  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };


  if (handovercandListLoading || handovercandListFetching) {
    return <div>Loading...</div>;
  }

  if (handovercandListSuccess) {
    return (
      <Disclosure.Panel as="dd" className="mt-0 pr-0">
        <div className="text-base leading-7 text-gray-600">
          {/* <h3>Bottom</h3> */}
          <div className="flex">
            <div className=" gap-4 basis-full">
              <div className="bg-white shadow-3xl gap-4 rounded-md ">
                <div className="bg-white rounded-md shadow-3xl border border-gray-100">
                  {/* <Interviewinterview /> */}

                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center"></div>

                    <div className="mt-0 mb-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-100 text-left text-md font-bold text-gray-900">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  sm:pl-6"
                                  >
                                    Candidate Name
                                  </th>
                                  {/* <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Job Title
                                  </th>

                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Branch
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Location
                                  </th> */}
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Joined Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Timeline Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Status
                                  </th>

                                  <th
                                    scope="col"
                                    className="py-3.5 w-2/12 pl-4 pr-3  sm:pl-6"
                                  >
                                    Signoff by
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Action{' '}
                                  </th>
                                </tr>
                              </thead>
                              {handovercandListObj?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  {handovercandListObj?.map((handover, i) => (
                                    <>
                                      {/* <pre>{JSON.stringify(interview)}</pre> */}
                                      <tr key={i}>
                                        <td className="whitespace-nowrap py-2 w-2/12 pl-4 pr-3 text-sm  sm:pl-6 border-r-2">
                                          {handover.id} - {handover.fullName}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {handover.negotiated_doj}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {handover.branch} -
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          <span className={handover.handover_approval_status === 1 ? "text-green-600" : "text-red-600"}>
                                            {handover.handover_approval_status === 1 ? "Onboarding" : "Pending"}
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                          {getUsername(handover.handover_approved_by)}
                                        </td>
                                        <td className="relative flex items-center justify-start whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                          <PencilSquareIcon
                                            className={`ml-1 mr-2 h-7 w-7 "
                                              }`}
                                              // className={`ml-1 mr-2 h-7 w-7 ${handover.handover_approval_status !== 1 ? "text-purple-500 cursor-pointer" : "text-gray-400 cursor-not-allowed"
                                              // }`}
                                            aria-hidden="true"
                                            onClick={() => {
                                              // if (handover.handover_approval_status !== 1) {
                                                gotoHandoverDetails('handoverdetails', handover);
                                              // }
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  <tr>
                                    <td
                                      colSpan={12}
                                      className="px-6 py-4  whitespace-nowrap text-sm text-black text-center font-semibold"
                                    >
                                      No Data Found
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    );
  }
}

export default HandoverCandidateList;
