import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
    DocumentArrowUpIcon,
    ArrowDownTrayIcon,
    XMarkIcon,
    ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import CandDetails from './CandDetails';


import {
    useCandHandoverDetailsQuery,
    useHandoverCandDocDeleteMutation,
    useHandoverChecklistMutation,
    useHandoverCandChecklistDeleteMutation,
    useCandWelcomeMailMutation,
    useCandidateSubmitReportMutation
} from '../../../../../features/hiringstages/handoverApiSlice';
import { useOfferDocumentMutation } from '../../../../../features/hiringstages/offerApiSlice';
import ModalData from '../../../../../components/common/ModalData';
import Modal from '../../../../../components/common/Modal';


const HandoverDetails = ({ toggleHandover }) => {
    const [showNotification, setShowNotification] = useState(false);
    const [openModalData, setOpenModalData] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // const [selectedfile, SetSelectedFile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [docFilePath, setDocFilePath] = useState('');
    const [docFileUrl, setDocFileUrl] = useState('');
    const [openModalDataValues, setOpenModalDataValues] = useState();
    const [fileNameTxt, setFileNameTxt] = useState('');
    const [fieldNameTxt, setFieldNameTxt] = useState('');
    const [deptId, setDeptId] = useState(null);
    const [modalData, setModalData] = useState();
    const [welcomTemplate, setWelcomeTemplate] = useState('');
    const [error, setError] = useState('');

    const candId = useSelector(selectCandidateInterview);
    const [candDocDelete] = useHandoverCandDocDeleteMutation();
    const [checklistDelete] = useHandoverCandChecklistDeleteMutation();
    const [offerDocument] = useOfferDocumentMutation();
    const [onboardingChecklist] = useHandoverChecklistMutation();
    const token = useSelector((data) => data.auth.token);
    const [welcomedescSubmit, { isLoading: welcomeDescLoading }] =
        useCandWelcomeMailMutation();
    const [candSumbitReport, { isLoading: candToReportLoading }] =
        useCandidateSubmitReportMutation();

    const {
        data: canddetails,
        isLoading: canddetailsLoading,
        isSuccess,
        refetch,
    } = useCandHandoverDetailsQuery(candId, { refetchOnMountOrArgChange: true });

    console.log('candDetails', canddetails);
    const docData = canddetails?.data?.documents;


    const openModalWindow = (id, type) => {
        setDeptId(id);
        setModalData({ id, type });
        setOpenModal(!openModal);
    };
    const closeModalWindow = () => {
        setOpenModal(!openModal);
    };
    console.log('deptId', deptId)

    const initialValues = {
        reportedToOffice: false
    };

    const validationSchema = yup.object({
        reportedToOffice: yup
            .boolean()
            .oneOf([true], 'Please confirm that the candidate has reported to the office.'),
    });


    const handleHandOver = async () => {
        try {
            const finalValues = {
                profile_id: canddetails?.data?.id,
            }
            const result = await candSumbitReport(finalValues).unwrap();
            alert(result.message);
            toggleHandover('handoverdetails', 'handover')
        } catch (error) {
            console.log("No documents for particular candidates. Update canceled: ", error)
            alert("No documents for particular candidates.Please add document must.");
        }        
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleHandOver,
        enableReinitialize: true,
    });
    const onModalSubmit = async () => {
        console.log('modalData', modalData);
        try {
            const { id, type } = modalData;
            console.log(id);
            console.log(type);
            if (type === 'document') {
                const deleteDept = await candDocDelete(id);
            } else if (type === 'onChecklist') {
                const deleteChecklist = await checklistDelete(id);
            }
            // console.log(deleteDept.message);
            closeModalWindow();
            showNotificationMsg();
            refetch();
        } catch (error) {
            alert('error occured when deleting')
        }
    };

    const openModalDataWindow = (actionType) => {
        setOpenModalDataValues(actionType);
        setOpenModalData(true);
    };

    const closeModalDataWindow = () => {
        setOpenModalData(false);
    };

    const showNotificationMsg = () => {
        setShowNotification(!showNotification);
    };


    const gotoHandoverList = (value, candid) => {
        toggleHandover(value);
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        setSelectedFile(e.target.files[0]);

        if (e.target.files[0] != '') {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);

            var options = formData;
            try {
                axios
                    .post('https://api.skillstrove.com/users/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setDocFilePath(response.data.data.filePath);
                        setDocFileUrl(response.data.data.url);
                        // formik.values.resume = response.data.filePath;
                    })
                    .catch((error) => { });
            } catch (error) { }
        }
    };


    async function submitDoc(e) {
        e.preventDefault();
        if (docFilePath != '' && fileNameTxt != '') {
            const data = {
                profile_id: candId,
                title: fileNameTxt,
                document_path: docFilePath,
            };
            try {
                if (openModalDataValues === 'uploadDoc') {
                    const documentapicall = await offerDocument(data).unwrap();
                    alert('Document added successfully');
                } else if (openModalDataValues === 'onboardingChecklist') {
                    const onboardingApiCall = await onboardingChecklist(data).unwrap();
                    alert('checklist added successfully');
                }

                setFileNameTxt('');
                setFieldNameTxt('')
                setSelectedFile([]);
            } catch (error) {
                alert('error occured');
            }
        }
        else
            alert('Please add file name and select file');
    }

    const handleDescriptionChange = (e) => {
        setWelcomeTemplate(e.target.value);
        if (e.target.value) setError('');
    };

    const handleSend = async () => {
        if (!welcomTemplate.trim()) {
            setError('Please enter the welcome description.');
            return; // Stop the function if validation fails
        }
        const finalValues = {
            profile_id: canddetails?.data?.id,
            email: canddetails?.data?.email,
            welcome_desc: welcomTemplate
        }
        console.log(finalValues)
        try {
            const result = await welcomedescSubmit(finalValues).unwrap();
            alert('Data Add Successfully');
            // toggleOffer('offerlist', 'offer')
        } catch (error) {
            console.log("Wecome error: ", error)
        }
    }

    // Place conditional return statements below useFormik
    if (canddetailsLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="flex justify-end  mb-4">
                <div className="mt-4 flex md:mt-0 md:ml-4 ">
                    <button
                        type="button"
                        className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
                        onClick={() => gotoHandoverList('handoverlist')}
                    >
                        <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text"
                            aria-hidden="true"
                        />
                        Back
                    </button>
                </div>
            </div>

            <div className="flex flex-row mt-0  gap-4">
                <CandDetails canddetails={canddetails} />

                <div className="basis-5/6">
                    <div className="flex flex-row  ">
                        <div className="w-full ">
                            <div className="bg-white shadow-lg rounded-lg">
                                <div className="">
                                    <div className="">
                                        <div className=" rounded-md shadow-3xl mx-0">
                                            {' '}
                                            <div className="bg-white  py-5 rounded-lg shadow-lg  px-10">
                                                <div className="flex justify-between">
                                                    <h1 className="text-xl font-bold text-center self-center">
                                                        Documents from Candidate
                                                    </h1>
                                                    <div className="flex">
                                                        {' '}

                                                        <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                                                            <a
                                                                // href={'#'}
                                                                // target="_blank"
                                                                // title="Edit"
                                                                type="file"
                                                                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                                                onClick={() => openModalDataWindow('uploadDoc')}
                                                            >
                                                                {' '}
                                                                <DocumentArrowUpIcon
                                                                    className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                                    aria-hidden="true"
                                                                />{' '}
                                                                Upload Document
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="mt-2 flow-root">
                                                    <div className="">
                                                        <div className="inline-block min-w-full py-2 align-middle">
                                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                <table className="min-w-full divide-y divide-gray-300">
                                                                    <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                                                            >
                                                                                File Name
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-bold text-gray-900"
                                                                            >
                                                                                Attachments
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-3 py-3.5 text-left flex justify-end mr-6 text-sm font-bold text-gray-900"
                                                                            >
                                                                                Action
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="divide-y divide-gray-200">
                                                                        {/* {people.map((person) => ( */}
                                                                        {docData?.length > 0 ? (
                                                                            docData?.map((document) => (
                                                                                // <tr key={document.id}>
                                                                                <tr key={document.id}>
                                                                                    <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                        {/* {person.name} */}
                                                                                        {/* Address Proof (Aadhar Card) */}
                                                                                        {document.title}
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap  text-sm text-gray-500">
                                                                                        {/* {person.title} */}
                                                                                        <span className=" flex whitespace-nowrap py-2 text-sm text-gray-500">
                                                                                            <a
                                                                                                href={document.document_path}
                                                                                                target="_blank"
                                                                                                title="Edit"
                                                                                                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                                                                            >
                                                                                                {' '}
                                                                                                <ArrowDownTrayIcon
                                                                                                    className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                                                                    aria-hidden="true"
                                                                                                />{' '}
                                                                                                Download
                                                                                            </a>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap flex justify-end mr-6  text-sm text-gray-500">
                                                                                        {/* {person.email} */}
                                                                                        <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                                                            <a
                                                                                                // href={'#'}
                                                                                                // target="_blank"
                                                                                                // title="Edit"
                                                                                                className="bg-red-500 px-4 py-2 items-center flex rounded-3xl text-white"
                                                                                                onClick={() => openModalWindow(document.id, 'document')}
                                                                                            >
                                                                                                {' '}
                                                                                                <XMarkIcon
                                                                                                    className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                                                                    aria-hidden="true"
                                                                                                />{' '}
                                                                                                Remove
                                                                                            </a>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan='3' className='py-4 text-center text-sm text-gray-500'>
                                                                                    No documents available
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-between mt-3">
                                                    <h1 className="text-xl font-bold text-center self-center">
                                                        Welcome Email
                                                    </h1>

                                                </div>

                                                <div className="mt-2 flow-root">
                                                    <div className="">
                                                        <div className="inline-block min-w-full py-2 align-middle">
                                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                <textarea
                                                                    id="welcom_emplate"
                                                                    name="welcomTemplate"
                                                                    rows="8"
                                                                    className="bg-inputbg border border-inputBorder block w-full text-lg rounded-md py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                                                    placeholder="Enter description here"
                                                                    value={welcomTemplate}
                                                                    onChange={handleDescriptionChange}
                                                                />
                                                                {error && (
                                                                    <div className="mt-2 text-sm text-red-600">
                                                                        {error}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end my-3 mx-6">
                                                        <button
                                                            type="button"
                                                            to={''}
                                                            className="flex justify-end rounded-3xl border border-transparent bg-violet-900 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                                                            onClick={handleSend}
                                                        >
                                                            Send Mail
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className=" mt-0 grid grid-cols-1 gap-6 px-0">
                                                    <div className="flex flex-row mt-5 justify-between">
                                                        <div className="basis-7/12 bg-gray-100  border py-0 mr-10 flex rounded-lg">
                                                            <div className="basis-3/4 p-3 mr-3">
                                                                <input
                                                                    type="checkbox"
                                                                    id="reportedToOffice"
                                                                    name="reportedToOffice"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    checked={formik.values.reportedToOffice}
                                                                    className="rounded border-gray-300 text-violet-900 focus:ring-violet-500"
                                                                />
                                                                <label htmlFor="reportedToOffice" className="text-lg pl-2 font-medium text-gray-700 cursor-pointer">
                                                                    Candidate reported to office
                                                                </label>
                                                                {formik.touched.reportedToOffice && formik.errors.reportedToOffice ? (
                                                                    <div className="mt-2 text-sm text-red-600">
                                                                        {formik.errors.reportedToOffice}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="basis-1/4 py-3 mr-3">
                                                                <button
                                                                    type="button"
                                                                    className=" rounded-xl border bg-green-500 w-full py-2.5 px-0 text-sm font-medium text-white shadow-sm focus:outline-none "
                                                                    onClick={formik.handleSubmit}
                                                                >
                                                                    Handover
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalData
                isModalDataOpen={openModalData}
                onModalDataClose={closeModalDataWindow}
            >

                <form onSubmit={submitDoc}>
                    <div className="grid grid-cols-4">
                        <div className="mt-3 ">
                            <label
                                htmlFor="file_name"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                            >
                                File name
                            </label>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="file_name"
                                id="file_name"
                                value={fileNameTxt}
                                onChange={(e) => setFileNameTxt(e.target.value)}
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                // value={formik.values.file_name}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                            />
                            {formik.touched.file_name &&
                                formik.errors.file_name ? (
                                <div className="mt-2 text-sm text-red-600">
                                    {formik.errors.file_name}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 mt-2">
                        <div className="mt-3 ">
                            <label
                                htmlFor="field_name"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                            >
                                Field name
                            </label>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="field_name"
                                id="field_name"
                                value={fieldNameTxt}
                                onChange={(e) => setFieldNameTxt(e.target.value)}
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                // value={formik.values.field_name}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                            />
                            {/* {formik.touched.field_name &&
              formik.errors.field_name ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.field_name}
                </div>
              ) : null} */}
                        </div>
                    </div>
                    <div className="flex justify-between mt-5 ">
                        <div className="kb-file-upload mb-0 w-1/4 mt-5">
                            <div className="file-upload-box flex whitespace-nowrap !rounded-xl px-3 bg-sidebar ">
                                <input
                                    type="file"
                                    id="fileupload"
                                    className="file-upload-input"
                                    accept=".doc,.docx,.pdf"
                                    onChange={handleFileChange}
                                    multiple
                                />
                                <span className="flex">
                                    <ArrowUpOnSquareIcon
                                        className="h-8 w-8 text-white mr-4"
                                        aria-hidden="true"
                                    />
                                    <span className=" text-white self-center">Upload</span>
                                </span>
                            </div>
                            {' '}
                            <p>{selectedFile.name} </p>
                        </div>
                        <div className='mt-5'>
                            <button
                                type="submit"
                                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8  mr-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
                            >
                                {/* Submit */}{openModalDataValues === 'onboardingChecklist' ? 'submit' : 'submit '}
                            </button>
                        </div>
                    </div>
                </form>
                {/* Modal window */}
            </ModalData>


            <Modal
                isModalOpen={openModal}
                onClose={() => closeModalWindow()}
                onSubmit={() => onModalSubmit()}
            ></Modal>
        </>
    );
};
export default HandoverDetails;
